.key-value-fields label {
    text-transform: capitalize;
}

.key-value-fields textarea {
    white-space: pre;
    overflow-x: auto;
    margin-bottom: -9px;
    padding-bottom: 9px;
}

.key-value-fields .MuiOutlinedInput-marginDense {
    overflow-y: hidden;
}

/* width */
.key-value-fields textarea::-webkit-scrollbar {
    height: 8px;
    cursor: pointer;
}

/* Track */
.key-value-fields textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
    margin: 0 -10px;
    cursor: pointer;

}

/* Handle */
.key-value-fields textarea::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
    margin: 0 -10px;
    cursor: pointer;
}

/* Handle on hover */
.key-value-fields textarea::-webkit-scrollbar-thumb:hover {
    background: #555;
}