.entity-drawer {
    max-height: calc(100vh - 198px);
}
.entity-drawer .MuiListItem-root {
    box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 20%);
    background-color: white;
    text-transform: capitalize;
}
.entity-drawer .group-name span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.entity-drawer .MuiListItem-root.new-group-btn,
.entity-drawer .MuiListItem-root.new-group-btn * {
    color: rgba(0, 0, 0, 0.38);
    transition: 0.2s;
}
.entity-drawer .MuiListItem-root.new-group-btn {
    padding-top: 0px;
    padding-bottom: 0px;
    box-shadow: none;
    background: none;
    margin-top: -4px;
}
.entity-drawer .MuiListItem-root.new-group-btn:hover,
.entity-drawer .MuiListItem-root.new-group-btn:hover * {
    color: rgba(0, 0, 0, 0.87);
}
.entity-drawer .MuiListItem-root.new-group-btn:hover {
    background: none;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    padding-top: 8px;
    padding-bottom: 8px;
}
.entity-drawer .MuiListItem-root:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.entity-drawer .MuiListItem-root .MuiSvgIcon-root.draggable {
    cursor: move;
}
.entity-drawer .MuiListItem-root.MuiIconButton-root {
    cursor: pointer;
}
.entity-drawer .MuiListItem-root.MuiListItem-gutters:first-child {
    margin-bottom: 8px;
    cursor: pointer;
    position: sticky;
    background: white;
    top: 0;
    z-index: 10000;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-top: 0;
    height: 64px;
}
.entity-drawer .MuiList-root {
    padding-bottom: 2rem;
}
.entity-drawer .MuiListItem-root.MuiListItem-gutters:first-child:hover {
    background: rgba(0, 0, 0, 0.05);
}
.entity-drawer .MuiListItem-root {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    margin: 0 0.4rem;
    width: calc(100% - 0.8rem);
    cursor: pointer;
}
.entity-drawer .MuiListItem-root + .MuiListItem-root {
    border-top: none;
    box-shadow: inset 0px 2px 2px 0px rgb(0 0 0 / 20%), 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.entity-drawer .MuiListSubheader-root {
    text-align: center;
    line-height: initial;
    padding: 6px 0 3px;
}
.entity-drawer .MuiListSubheader-root:active {
    pointer-events: none;
}
.entity-drawer .MuiListSubheader-root.empty::before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    height: calc(50% - 3px);
    top: unset;
    border-top: none;
    border-bottom: 2px dashed rgba(0, 0, 0, 0.25);
}
.entity-drawer > .MuiDrawer-paper {
    position: sticky;
}
.entity-drawer .MuiListItemIcon-root {
    min-width: 40px;
}

.MuiCollapse-wrapperInner{
    display: flex;
    justify-content: space-around;
}


.MuiListItem-root.MuiListItem-gutters.drawer-list-item {
    padding-left: 5px;
    padding-right: unset;
}