.cms {
    display: grid;
    margin-bottom: -24px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    max-height: calc(100vh - 198px);
}

.cms-root {
    margin-bottom: -24px;
    max-height: calc(100vh - 198px);
    display: block;
}

.cms>.entity-drawer {
    width: 200px;
    grid-row: 1 / 3;
    z-index: 1101;
    margin: 0;
    display: block;
    height: 110%;
}

.cms .entity-container {
    display: grid;
    grid-template-rows: 1fr auto;
    height: calc(100vh - 262px);
}

.cms .group-entities {
    overflow: auto;
    padding: 25px;
}

.cms ul {
    padding: 0;
    height: 100%;
}

.cms .MuiList-root {
    width: 100%;
    max-width: 360;
    position: relative;
    overflow: auto;
}

.cms .app-bar {
    display: block;
    justify-content: unset;
    align-items: unset;
    grid-column: 2/3;
    margin: unset;
}

.cms .sh-footer {
    padding-bottom: 8px;
    background-color: white;
}

.MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-clickable {
    border-color: #007bff;
    margin-left: 5px;
    margin-right: 5px;
}

.footer-seperator {
    margin-bottom: .5rem;
    margin-top: 0;
}

.sh-footer div {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}

.MuiButtonBase-root.MuiChip-root.current-chip.MuiChip-outlined.MuiChip-clickable {
    border-color: green;
}

.MuiListItem-root.drawer-list-item.MuiListItem-gutters.test {
    border-bottom: 4px solid #007bff;
    margin-bottom: 2px;
    padding-bottom: 4px;
}