.tool-container > .tool-header,
.tool-container > .tool-contents {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
}
.tool-container > .tool-contents {
    padding-bottom: 24px;
}
.tool-container > hr {
    margin-top: 24px;
    margin-bottom: 24px;
}
.tool-container.containment-padded > .tool-header,
.tool-container.containment-padded > .tool-contents {
    max-width: unset;
}
.tool-container.containment-none > hr,
.tool-container.containment-none > .tool-header,
.tool-container.containment-none > .tool-contents {
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}
.tool-container.containment-none > .tool-contents {
    padding-left: 0;
    padding-right: 0;
}
.tool-container.containment-none > hr {
    margin-bottom: 0;
}
.tool-container > .tool-header h1 {
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}
.tool-container > .tool-header {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tool-container nav {
    margin: -2px 24px -22px;
}
@media (max-width: 768px) {
    .tool-container > .tool-header:first-child > h1 {
        font-size: xx-large;
    }
}
@media (max-width: 480px) {
    .tool-container > .tool-header:first-child > h1 {
        font-size: x-large;
    }
}
.tool-container > .tool-header:first-child > button {
    min-width: 170px;
}
.tool-container .breadcrumbs a {
    display: flex;
    align-items: center;
    color: #bdbdbd;
    cursor: pointer;
}
.tool-container .breadcrumbs a:hover {
    color: #0089cf;
    text-decoration: underline;
}
.tool-container .breadcrumbs li:last-child a {
    pointer-events: none;
    color: #0089cf;
}
