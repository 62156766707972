body {
  margin: 0;
  background-color: #f5f5f5;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.root {
  width: 100%;
  overflow-x: auto;
}

.clickable {
  cursor: pointer;
  transition: transform .3s;
}
.clickable:hover {
  transform: scale(1.05);
}

.loading-screen {
  background: rgba(200, 200, 200, 0.5);
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  line-height: 100vh;
  z-index: 10000;
}

.loading-screen img {
  width: 100%;
  max-width: 250px;
}

.tools-tab {
  cursor: pointer;
  margin: 12px;
  padding: 5px;
  width: fit-content;
}

.tools-tab img {
  height: 42px;
  padding-top: 12px;
}

.tools-tab h4 {
  margin: 0px;
}

.tools-tab:hover {
  color: #007ec8;
  transition: ease-in-out 0.8s;
  background: #bdc3c7;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1rem 0;
}

header .nav-bar {
  white-space: nowrap;
}

header .nav-bar > * + * {
  margin-left: 1rem;
}

@media (max-width: 768px) {
  header {
    display: block;
  }

  header .nav-bar {
    white-space: normal;
  }

  header .nav-bar > * + * {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }

  header .nav-bar button {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Components */

/* Material UI CSS Navigation */

.App .main-nav {
  height: fit-content;
  padding: 24px;
  margin: 0;
}

.App .nav-item {
  float: right;
}

.App .wrapper {
  width: 90vw;
  padding: 24px;
  margin: 0 auto;
}

.App .nav-item svg {
  font-size: 2.5rem;
}

hr {
  border: 1.5px solid #007ec8;
  margin: 1rem 0;
}

.text-muted {
  color: rgba(0, 0, 0, 0.38) !important;
}

.App .nav-item svg:hover {
  font-size: 2.5rem;
  color: #0088cf;
  transition: ease-in-out 0.3s;
}

.App .MuiAppBar-positionStatic-5 {
  margin: 0;
}

.App .MuiAppBar-colorPrimary-8 {
  background-color: hsl(210, 10%, 23%);
  padding-bottom: 27px;
}

.App .jss5 {
  margin: 0;
}

.App .jss8 {
  background-color: hsl(210, 10%, 23%);
  padding-bottom: 27px;
}

.App .container {
  width: 100%;
}

/* App/Root Component */
.show-grid {
  padding-top: 16px;
}

.App .main-nav {
  background: hsl(210, 10%, 23%);
  padding: 24px;
}

.App .logo {
  width: 200px;
  padding-top: 8px;
  height: auto;
  float: left;
}