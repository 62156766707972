.App button:focus {
    outline: unset;
}
.logout {
    height: 100vh;
    width: 100vw;
    background-image: url(https://d1ra4hr810e003.cloudfront.net/visual/accountbackground/A7F8955A-7B0C-4687-8318AD6B52970175/medium-5F54CD75-B0F1-4EDC-919F8D55090E6C1A.jpg);
    vertical-align: middle;
    display: table-cell;
}
.logout > div {    
    margin-left: auto;
    margin-right: auto;
    position: relative;
    max-width: 440px;
    width: calc(100% - 40px);
    padding: 44px;
    margin-bottom: 28px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
}
.logout .logout-text {
    color: #1b1b1b;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: "Segoe UI","Helvetica Neue","Lucida Grande","Roboto","Ebrima","Nirmala UI","Gadugi","Segoe Xbox Symbol","Segoe UI Symbol","Meiryo UI","Khmer UI","Tunga","Lao UI","Raavi","Iskoola Pota","Latha","Leelawadee","Microsoft YaHei UI","Microsoft JhengHei UI","Malgun Gothic","Estrangelo Edessa","Microsoft Himalaya","Microsoft New Tai Lue","Microsoft PhagsPa","Microsoft Tai Le","Microsoft Yi Baiti","Mongolian Baiti","MV Boli","Myanmar Text","Cambria Math";
    margin-top: 16px;
    margin-bottom: 12px;
}
.logout img {
    height: 30px;
    width: 100px;
}
.logout .logout-btn {
    text-align: right;
    margin-top: 150px;
}
.logout .logout-btn button {
    width: 108px;
    padding: 4px 12px;
    background-color: #0067b8;
    border-style: none;
    color: #fff;
    border-radius: 0;
    font-size: 15px;
}
.logout .logout-btn button:hover {
    background-color: #005da6
}
.logout .logout-btn button:focus {
    text-decoration: underline;
    outline: 2px solid #000;
}