.entity-card {
  transition: 0.2s;
}
.entity-card a:hover {
  text-decoration: none;
  cursor: pointer;
}
.entity-card .header {
  transition: 0.2s;
  padding: 0;
}
.entity-card .header .MuiCardHeader-avatar {
  margin: 0;
}
.entity-card .header .MuiCardHeader-avatar button {
  cursor: move;
}
.entity-card .header > *,
.entity-card .header button {
  transition: 0.2s;
}
.entity-card .header > .MuiCardHeader-content,
.entity-card .header > .MuiCardHeader-content > span {
  transition: 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: large;
}
.entity-card .image {
  transition: 0.2s;
  padding-top: 56.25%; /*16:9*/
  height: 0;
  background-size: contain;
  background-color: #e0e0e0;
  display: grid;
  place-items: center;
}
.entity-card .image.squeezed {
  padding-top: 28.125%;
}
.entity-card .image.squeezed.missing {
  display: none;
}
.entity-card .image.squeezed + div {
  height: 0;
  padding-bottom: calc(28.125% - 8px);
  padding-top: 8px;
  box-shadow: inset 0px -1px 3px -2px;
  margin-bottom: -1px;
}
.entity-card .image.squeezed.missing + div {
  padding-bottom: calc(56.25% - 8px);
  border-top: 1px solid lightgray;
}
.entity-card .image svg {
  transition: 0.2s;
  margin-top: -56.25%;
  color: #ccc;
  font-size: xx-large;
}
.entity-card .image.squeezed svg {
  margin-top: -28.125%;
  display: none;
}
.entity-card .header .MuiCardHeader-action {
  margin: 0;
}
.entity-card .MuiCard-root.card:hover .image {
  background-blend-mode: soft-light;
}
.entity-card .MuiCard-root.card:not(:hover) .image > div {
  opacity: 0.35;
}
.entity-card .pre-card {
  display: grid;
  grid-template-columns: 1fr auto;
}
.entity-card .MuiCard-root {
  grid-column: 1;
  grid-row: 1;
  transition: 0.2s;
  transition-delay: 0.1s;
}
.entity-card .add {
  padding-left: 0;
  padding-right: 24px;
  margin-right: -24px;
  margin-left: -24px;
  min-width: unset;
  grid-column: 2;
  transition: 0.2s;
  transition-delay: 0.1s;
  cursor: cell;
}
.entity-card .add:hover {
  margin-right: -24px;
  padding-right: 6px;
  padding-left: 6px;
}
.entity-card .add:hover + .MuiCard-root {
  margin-left: -20px;
  margin-right: 20px;
}
.dense.entity-card .header {
  padding: 0;
}
.dense.entity-card .header .MuiCardHeader-action,
.dense.entity-card .header > .MuiCardHeader-content,
.dense.entity-card .header > .MuiCardHeader-content > span {
  font-size: small;
  margin: 0;
}
.dense.entity-card .image {
  padding-top: 18.75%;
  background-size: 60%;
}
.dense.entity-card .image svg {
  margin-top: -18.75%;
}
.dense.entity-card .header svg {
  font-size: 1.25rem;
}

.MuiMenu-paper {
  margin-left: 50px;
  margin-top: 2px;
}

.card-footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  padding: unset;
  height: calc(100% - 50px);
  width: 100%;
  border: unset;
  margin-top: 50px;
  align-items: inherit;
  /* background-color: rgba(0, 0, 0, 0.2); */
  /* backdrop-filter: blur(1.5px) */
}

.card-footer-dense {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  padding: unset;
  width: 100%;
  border: unset;
  margin-top: 28px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.2);
}

.groupless {
  grid-template-columns: auto;
}
